import React from 'react'
import './howitworks.css'
import hiwimg from './images/howitworks.png'

function HowItWorks() {
  return (
    <section  class="how-it-works">
        <div class="hiw-heading">
            <strong>Home - How it Works</strong>
            <h2>How It Works</h2>
        </div>
        <div class="hiw-image" data-aos="fade-up">
            <img class="img-hiw" src={hiwimg} />
        </div>
    </section>
  )
}

export default HowItWorks