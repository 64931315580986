import React from 'react'
import './Wallet.css'
import f1 from './images/feature-1.png'
import f2 from './images/feature-2.png'
import f3 from './images/feature-3.png'
import f4 from './images/feature-4.png'
import f5 from './images/feature-5.png'
import f6 from './images/feature-6.png'

function Wallet() {
  return (
    <section id="feature-wallet">
        <h2>ALL <span>WALLET</span> OPTIONS</h2>
        <ul id="feature-boxes"  className="container cs-hidden">
            <li className="item" data-aos="zoom-in">
                <div className="feature-box">
                    <a href="#">
                        <img src={f1} />
                    </a>
                </div>
                <span>Meta Mask</span>
            </li>
            <li className="item" data-aos="zoom-in">
                <div className="feature-box">
                    <a href="#">
                        <img src={f2}/>
                    </a>
                </div>
                <span>Binance</span>
            </li>
            <li className="item" data-aos="zoom-in">
                <div className="feature-box">
                    <a href="#">
                        <img src={f3}/>
                    </a>
                </div>
                <span>Formatic</span>
            </li>
            <li className="item" data-aos="zoom-in">
                <div className="feature-box">
                    <a href="#">
                        <img src={f4}/>
                    </a>
                </div>
                <span>Autherum</span>
            </li>
            <li className="item" data-aos="zoom-in">
                <div className="feature-box">
                    <a href="#">
                        <img src={f5}/>
                    </a>
                </div>
                <span>Coinbase</span>
            </li>
            <li className="item" data-aos="zoom-in">
                <div className="feature-box">
                    <a href="#">
                        <img src={f6}/>
                    </a>
                </div>
                <span>Portis</span>
            </li>
            
        </ul>
    </section>
  )
}

export default Wallet