import './App.css';
import Home from './Pages/Home'
import { BrowserRouter,Routes ,Route } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
