import React,{useState } from 'react'
import './navbar.css'
import logo from './images/logo.png'
import mint from './images/mint.png'
import metamask from './images/metamask.png'

function Navbar() {
    const [nav,setnav] = useState(false);

    const changeBackground = () => {
        if(window.scrollY >= 50) {
            setnav(true);
        }
        else{
            setnav(false);
        }
    }
    window.addEventListener('scroll', changeBackground);
  return (
    <nav className={nav ? 'navigation active' : 'navigation'}>
        <a href="index.html" className="logo">
            <img className="logo" src={logo} alt="" />
        </a>
        <input type="checkbox" className="menu-btn" id="menu-btn"/>
        <label for="menu-btn" className="menu-icon">
            <span className="nav-icon"></span>
        </label>
        <ul className="menu">
            <li><a href="">Home</a></li>
            <li><a href="">about</a></li>
            <li><a href="">explore</a></li>
            <li><a href="">how to play</a></li>
            <li><a href="">marketplace</a></li>
        </ul>
        <ul className="nav-imgs">
            <li><a href=""><img className="nav-img" src={mint} /></a></li>
            <li><a href=""><img className="nav-img" src={metamask} /></a></li>
        </ul>
    </nav>
  )
}

export default Navbar