import React from 'react'
import About from '../Components/AboutUs/About'
import Explore from '../Components/Explore/Explore'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import HowItWorks from '../Components/Howitworks/HowItWorks'
import Navbar from '../Components/Navbar/Navbar'
import Nftservice from '../Components/NFTService/Nftservice'
import Wallet from '../Components/Wallets/Wallet'


function Home() {
  return (
    <>
        <Navbar/>
        <Header/>
        <Wallet/>
        <About/>
        <Nftservice/>
        <Explore/>
        <HowItWorks/>
        <Footer/>
    
    </>
  )
}

export default Home