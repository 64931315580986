import React from 'react'
import './footer.css'
import logo from './images/logo.png'
import listicon from './images/footer-link.png'
import ficon from './images/facebook.png'
import ticon from './images/twitter.png'
import ricon from './images/reddit.png'
import teicon from './images/telegram.png'

function Footer() {
  return (
    <footer>
        <div class="footer-container">
            <div class="footer-container-flex">
                <div class="footer-heading">
                    <img class="footer-image" src={logo}  />
                </div>
                <div class="footer-content">
                    <div class="footer-box">
                        <strong>Quick Links</strong>
                        <ul>
                            <li><img src={listicon} /><a href="#">White Paper</a></li>
                            <li><img src={listicon} /><a href="#">User Support</a></li>
                            <li><img src={listicon} /><a href="#">Report A Bug</a></li>
                            <li><img src={listicon} /><a href="#">Explore</a></li>
                        </ul>
                    </div>
                    <div class="footer-box">
                        <strong>Quick Links</strong>
                        <ul>
                            <li><img src={listicon} /><a href="#">Buy Wire NFT</a></li>
                            <li><img src={listicon} /><a href="#">Contact Us</a></li>
                            <li><img src={listicon} /><a href="#">Terms & Services</a></li>
                            <li><img src={listicon} /><a href="#">Privacy Policy</a></li>
                            <li><img src={listicon} /><a href="#">Media Kits</a></li>
                        </ul>
                    </div>
                    <div class="footer-box">
                        <strong>Quick Links</strong>
                        <ul>
                            <li><img src={ficon} /><a href="#">Facebook</a></li>
                            <li><img src={ticon} /><a href="#">Twitter</a></li>
                            <li><img src={ricon}  /><a href="#">Reddit</a></li>
                            <li><img src={teicon} /><a href="#">Telegram</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="copyright">
                    <span>All Rights Reserved</span>
                    <span>&#169; Wire NFT</span>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer