import React from 'react'
import './nftservice.css'
import box1 from './images/box-1.png'
import box2 from './images/box-2.png'
import box3 from './images/box-3.png'

function Nftservice() {
  return (
        <section id="nftservice">
            <div class="nftservice-box-container">
                <div class="nftservice-box" data-aos="flip-left">
                    <div class="box-top">
                        <div class="service">
                            <div class="service-img">
                                <img src={box1} />
                            </div>
                            <div class="name-service">
                                <strong>RACING</strong>
                            </div>
                        </div>
                    </div>
                    <div class="service-detail">
                        <p>Each NFT Horse you own can be taken to Race by paying certain Entry fees in Gaming Token and depending on the Attributes of NFT, you will have chances of winning the Race. Every Win will give you Reward Token and there is no limit in the number of Races you can participate every day.</p>
                    </div>
                </div>
                <div class="nftservice-box" data-aos="flip-left">
                    <div class="box-top">
                        <div class="service">
                            <div class="service-img">
                                <img src={box2} />
                            </div>
                            <div class="name-service">
                                <strong>BREEDING</strong>
                            </div>
                        </div>
                    </div>
                    <div class="service-detail">
                        <p>If you have a Male & Female NFT horse, you can breed them by paying certain Breeding fees and you can resell them for profit in Marketplace or use to Play for fun or even breed for your future profit. The more NFTs you have, the better.
                        </p>
                    </div>
                </div>
                <div class="nftservice-box" data-aos="flip-left">
                    <div class="box-top">
                        <div class="service">
                            <div class="service-img">
                                <img src={box3} />
                            </div>
                            <div class="name-service">
                                <strong>RENTING</strong>
                            </div>
                        </div>
                    </div>
                    <div class="service-detail">
                        <p>The NFTs you Mint is a Regular income generator for you from different activities in Ecosystem. In case, you are unable to participate in Race, you can Rent your NFT to Scholars and make Passive income from your NFTs.</p>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default Nftservice