import React,{useEffect} from 'react'
import './header.css'
import headerimage from './images/header.png'
import AOS from 'aos'
import 'aos/dist/aos.css';

function Header() {
    useEffect(()=>{
        AOS.init({duration:2000});
    })


  return (
    <section id='main'>
        <div className="main-img" data-aos="flip-right">
            <img alt="" src={headerimage}/>
        </div>
        <div className="main-text">
            <h1><span>CREATE, COLLECT</span><br/> AND <span>SELL</span><br/> DIGITAL ITEMS</h1>
            <p>Digital Marketplace For Crypto Collectibles And Non-Fungible 
                Tokens. Buy, Sell, And Discover Exclusive Digital Assets.</p>
            <div className="main-btns">
                <a className="active-btn-header service-btn" href="">EXPLORE NOW</a>
                <a href="" className="about-btn">HOW TO PLAY</a>
            </div>
        </div>
    </section>
  )
}

export default Header