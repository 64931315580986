import React from 'react'
import exploreimg from './images/explore.png';
import './explore.css'

function Explore() {
  return (
    <section id="explore">
        <div className="explore-text">
            <strong>Home - Explore</strong>
            <h1><span>EXPLORE</span></h1>
            <div className="explore-btns">
                <a className="active-btn-header service-btn" href="#services">Go To Home</a>
            </div>
        </div>
        <div className="explore-img" data-aos="fade-left">
            <img  alt="" src={exploreimg} />
        </div>
    </section>
  )
}

export default Explore