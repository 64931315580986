import React from 'react'
import aboutimg from './images/about-us.png';
import './about.css';

function About() {
  return (
    <section id="about">
        <div className="about-text" data-aos="zoom-out">
            <strong>Who We Are</strong>
            <h1><span>ABOUT</span> US</h1>
            <p>LaRace is a Unique Metverse NFT Hypercasual Play2Earn Game, with a
                very Unique Sustainable Business model with a combination of 
                multiple industries like NFT, Play2Earn, Metaverse & Horse Racing</p>
            <div className="about-btns">
                <a className="active-btn-header service-btn" href="">READ MORE</a>
            </div>
        </div>
        <div className="about-img" data-aos="fade-left">
            <img  alt="" src={aboutimg} />
        </div>
    </section>
  )
}

export default About